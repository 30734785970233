import React, {useEffect, useState} from 'react';
import { Layout, Space, Card, Button, Alert, message, Upload } from 'antd';
import { DownloadOutlined, UploadOutlined, SaveOutlined} from '@ant-design/icons';
import Cookie from "js-cookie";
import { useNavigate } from "react-router-dom";
import Loader from "../common/Loader";
import axios from "axios";
import '../styles/UploadFile.css';

const { Sider, Content } = Layout;

const contentStyle = {
  textAlign: 'center',
  minHeight: 120,
  lineHeight: '120px',
  color: '#fff',
  backgroundColor: 'rgb(240 242 245)',
};
const siderStyle = {
  textAlign: 'center',
  color: '#fff',
  backgroundColor: '#014373',
};
const UploadFile = ({downloadXlsxClientesDigitalesPath, uploadXlsxClientesDigitalesPath, downloadXlsxHorariosCampaniasPath, uploadXlsxHorariosCampaniasPath}) => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [fileClientesDigitalesList, setFileClientesDigitalesList] = useState([]);
  const [fileHorariosCampaniasList, setFileHorariosCampaniasList] = useState([]);

  useEffect(() => {
    if (!Cookie.get("userLogin")) {
      navigate("/kain/login", { replace: true });
    }
    // eslint-disable-next-line
  }, []);

  const logOut = () => {
      Cookie.remove("userLogin");
      navigate("/kain/login", { replace: true });
  }

  const downloadClientesDigitalesFunction = () => {
    setLoading(true);
    const {username, password} = JSON.parse(Cookie.get("userLogin"))
    const urlDownload = `${downloadXlsxClientesDigitalesPath}`
        axios
            .get(`${urlDownload}`,
                {
                    auth: {
                        username: username,
                        password: password
                    },
                    responseType: 'blob'
                })
            .then((res) => {
                if ((res.status === 200)) {
                    const href = URL.createObjectURL(res.data);
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', 'phoneNumbers.xlsx');
                    link.setAttribute('target','_blank');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                    setLoading(false);
                }else{
                  message.error(
                    `Error al descargar el archivo xlsx.Intentelo de nuevo o Contacte a Soporte. Código de Error: ${res.status}`,
                    3
                  );
                  setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                if(err.response.status === 404){
                  message.error(
                    `El archivo xlsx no existe.`,
                    3
                  );
                  setLoading(false);
                }else{
                  message.error(
                    `Error al descargar el archivo xlsx.Intentelo de nuevo o Contacte a Soporte. Código de Error: ${err.code}`,
                    3
                  );
                  setLoading(false);
                }
            })
  }
  const downloadHorariosCampaniasFunction = () => {
    setLoading(true);
    const {username, password} = JSON.parse(Cookie.get("userLogin"))
    const urlDownload = `${downloadXlsxHorariosCampaniasPath}`
        axios
            .get(`${urlDownload}`,
                {
                    auth: {
                        username: username,
                        password: password
                    },
                    responseType: 'blob'
                })
            .then((res) => {
                if ((res.status === 200)) {
                    const href = URL.createObjectURL(res.data);
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', 'HorariosCampañas.xlsx');
                    link.setAttribute('target','_blank');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                    setLoading(false);
                }else{
                  message.error(
                    `Error al descargar el archivo xlsx.Intentelo de nuevo o Contacte a Soporte. Código de Error: ${res.status}`,
                    3
                  );
                  setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                if(err.response.status === 404){
                  message.error(
                    `El archivo xlsx no existe.`,
                    3
                  );
                  setLoading(false);
                }else{
                  message.error(
                    `Error al descargar el archivo xlsx.Intentelo de nuevo o Contacte a Soporte. Código de Error: ${err.code}`,
                    3
                  );
                  setLoading(false);
                }
            })
  }
  const uploadClientesDigitalesFunction = () => {
    setLoading(true);
    var formData = new FormData();
    formData.append("file", fileClientesDigitalesList[0]);
    const {username, password} = JSON.parse(Cookie.get("userLogin"))
    const urlUploadCsv = `${uploadXlsxClientesDigitalesPath}`
        axios
            .post(`${urlUploadCsv}`,formData,
                {
                    auth: {
                        username: username,
                        password: password
                    },
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    }
                })
            .then((res) => {
                if ((res.status === 200)) {
                  message.success("Archivo xlsx subido exitosamente.", 4);
                    setLoading(false);
                }else{
                  message.error(
                    `Error al subir el archivo xlsx.Intentelo de nuevo o Contacte a Soporte. Código de Error: ${res.status}`,
                    3
                  );
                  setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                if(err.response.status === 404){
                  message.error(
                    `El archivo xlsx no existe.`,
                    3
                  );
                  setLoading(false);
                }else{
                  message.error(
                    `Error al subir el archivo xlsx.Intentelo de nuevo o Contacte a Soporte. Código de Error: ${err.code}`,
                    3
                  );
                  setLoading(false);
                }
            })
  }
  const uploadHorariosCampaniasFunction = () => {
    setLoading(true);
    var formData = new FormData();
    formData.append("file", fileHorariosCampaniasList[0]);
    const {username, password} = JSON.parse(Cookie.get("userLogin"))
    const urlUploadCsv = `${uploadXlsxHorariosCampaniasPath}`
        axios
            .post(`${urlUploadCsv}`,formData,
                {
                    auth: {
                        username: username,
                        password: password
                    },
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    }
                })
            .then((res) => {
                if ((res.status === 200)) {
                  message.success("Archivo xlsx subido exitosamente.", 4);
                    setLoading(false);
                }else{
                  message.error(
                    `Error al subir el archivo xlsx.Intentelo de nuevo o Contacte a Soporte. Código de Error: ${res.status}`,
                    3
                  );
                  setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                if(err.response.status === 404){
                  message.error(
                    `El archivo xlsx no existe.`,
                    3
                  );
                  setLoading(false);
                }else{
                  message.error(
                    `Error al subir el archivo xlsx.Intentelo de nuevo o Contacte a Soporte. Código de Error: ${err.code}`,
                    3
                  );
                  setLoading(false);
                }
            })
  }
  const uploadClientesDigitalesProps = {
    onRemove: (file) => {
      const index = fileClientesDigitalesList.indexOf(file);
      const newFileList = fileClientesDigitalesList.slice();
      newFileList.splice(index, 1);
      setFileClientesDigitalesList(newFileList);
    },
    beforeUpload: (file) => {
      setFileClientesDigitalesList([file]);
      return false;
    },
    fileClientesDigitalesList,
  };
  const uploadHorariosCampaniasProps = {
    onRemove: (file) => {
      const index = fileHorariosCampaniasList.indexOf(file);
      const newFileList = fileHorariosCampaniasList.slice();
      newFileList.splice(index, 1);
      setFileHorariosCampaniasList(newFileList);
    },
    beforeUpload: (file) => {
      setFileHorariosCampaniasList([file]);
      return false;
    },
    fileHorariosCampaniasList,
  };

  return(
    <div style={{
        width : '100%',
        height : '100%',
    }}>
      <Layout style={{ width: "100%", height: "100%" }}>
        <Sider style={siderStyle}>
              <img
                  src={`https://gepp.com.mx/wp-content/uploads/2017/12/GEPPBlanco-1024x571.png`}
                  alt="logo"
                  className="upload-file-logo"
              />
              <Card
                style={{
                  backgroundColor: '#014373',
                  color: 'white',
                }}
                bordered={false}
              >
                  <p>Sesión</p>
                  <Button type="primary"
                  onClick={logOut}>Cerrar Sesión</Button>
              </Card>
        </Sider>
        <Layout>
          <Content style={contentStyle}>
            <Space
              direction="vertical"
              size= {20}
              style={{
                width: '95%',
                textAlign: 'left',
                alignItems: 'center',
              }}
              split
            >
            <Alert
              message="Información Clientes Digitales"
              description="Al subir un nuevo archivo se borrará la lista actual de clientes digitales. Tome esto en cuenta a la hora de subir un nuevo archivo."
              type="info"
              showIcon
            />
            <Card
              title="Descargar Archivo xlsx de Clientes Digitales"
              bordered={false}
              style={{
                width: 500,
                textAlign: 'center'
              }}
            >
              <Button 
                type="primary" 
                icon={<DownloadOutlined />} 
                size="large"
                onClick={downloadClientesDigitalesFunction}
              >
                Download
              </Button>
            </Card>
            <Card
              title="Subir Archivo xlsx de Clientes Digitales"
              bordered={false}
              style={{
                width: 500,
                textAlign: 'center'
              }}
            >
              <Upload {...uploadClientesDigitalesProps}>
                <Button type="primary" 
                  icon={<UploadOutlined />} 
                  size="large"
                  style={{
                    backgroundColor: 'red'
                  }}
                  >
                  Seleccionar Archivo
                </Button>
              </Upload>
              <div style={{
                padding: 10,
              }}>
              <Button 
                type="primary" 
                icon={<SaveOutlined />} 
                size="large"
                disabled={fileClientesDigitalesList.length === 0}
                onClick={uploadClientesDigitalesFunction}>
                Guardar
              </Button>
              </div>
            </Card>
            <Alert
              message="Información Horarios de Campañas"
              description="Al subir un nuevo archivo se borrará la lista actual de horarios de campañas. Tome esto en cuenta a la hora de subir un nuevo archivo."
              type="info"
              showIcon
            />
            <Card
              title="Descargar Archivo xlsx de Horarios de Campañas"
              bordered={false}
              style={{
                width: 500,
                textAlign: 'center'
              }}
            >
              <Button 
                type="primary" 
                icon={<DownloadOutlined />} 
                size="large"
                onClick={downloadHorariosCampaniasFunction}
              >
                Download
              </Button>
            </Card>
            <Card
              title="Subir Archivo xlsx de Horarios de Campañas"
              bordered={false}
              style={{
                width: 500,
                textAlign: 'center'
              }}
            >
              <Upload {...uploadHorariosCampaniasProps}>
                <Button type="primary" 
                  icon={<UploadOutlined />} 
                  size="large"
                  style={{
                    backgroundColor: 'red'
                  }}
                  >
                  Seleccionar Archivo
                </Button>
              </Upload>
              <div style={{
                padding: 10,
              }}>
              <Button 
                type="primary" 
                icon={<SaveOutlined />} 
                size="large"
                disabled={fileHorariosCampaniasList.length === 0}
                onClick={uploadHorariosCampaniasFunction}>
                Guardar
              </Button>
              </div>
            </Card>
            <div/>
          </Space>
          </Content>
        </Layout>
      </Layout>
      {loading && <Loader />}
    </div>
  )
};
export default UploadFile;