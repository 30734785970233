import React from "react";
import { LoadingOutlined } from "@ant-design/icons";

export default function Loader() {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100vh",
        zIndex: 1000,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        opacity: "0.5",
        background: "white"
      }}
    >
      <LoadingOutlined  
        style={{ fontSize: "10rem", color: "#D50029" }}
      />
    </div>
  );
}