import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Navigate,
  Route,
} from "react-router-dom";
import Login from "./views/Login";
import UploadFile from "./views/UploadFile";

function App({loginPath,
  downloadXlsxClientesDigitalesPath, uploadXlsxClientesDigitalesPath, downloadXlsxHorariosCampaniasPath, uploadXlsxHorariosCampaniasPath}) {
  return (
    <Router>
      <Routes>
        <Route
          path="/kain/login"
          element={
            <Login
              loginPath={loginPath}
            />
          }
        />
        <Route
          path="/kain/uploadfile"
          element={
            <UploadFile
              downloadXlsxClientesDigitalesPath={downloadXlsxClientesDigitalesPath}
              uploadXlsxClientesDigitalesPath={uploadXlsxClientesDigitalesPath}
              downloadXlsxHorariosCampaniasPath={downloadXlsxHorariosCampaniasPath}
              uploadXlsxHorariosCampaniasPath={uploadXlsxHorariosCampaniasPath}
            />
          }
        />
        <Route path="*" element={<Navigate to="/kain/login" />} />
      </Routes>
    </Router>
  );
}

export default App;
