import React from 'react';
import ReactDOM from 'react-dom/client';
import './App.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App
      loginPath={window.kainSettings.loginPath}
      downloadXlsxClientesDigitalesPath={window.kainSettings.downloadXlsxClientesDigitalesPath}
      uploadXlsxClientesDigitalesPath={window.kainSettings.uploadXlsxClientesDigitalesPath}
      downloadXlsxHorariosCampaniasPath={window.kainSettings.downloadXlsxHorariosCampaniasPath}
      uploadXlsxHorariosCampaniasPath={window.kainSettings.uploadXlsxHorariosCampaniasPath}
    />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
