import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Card, Form, Input, message, Typography } from "antd";
import Cookie from "js-cookie";
import { useNavigate } from "react-router-dom";
import Loader from "../common/Loader";
import "../styles/Login.css";

const { Title } = Typography;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

export default function Login({loginPath}) {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (Cookie.get("userLogin")) {
      navigate("/kain/uploadfile")
    }
    // eslint-disable-next-line
  }, [])

  const onFinish = (values) => {
    setLoading(true);
    axios
      .post(`${loginPath}`, {}, {
        auth: {
          username: values.username,
          password: values.password
        }
      })
      .then((res) => {
        if (res.status === 200) {
          Cookie.set(
            "userLogin",
            JSON.stringify({
              username: values.username,
              password: values.password,
            }, { expires: 1 })
          );
          form.resetFields();
          setLoading(false);
          message.success("Login Success", 4);
          navigate("/kain/uploadfile", { replace: true });
        }
      })
      .catch((err) => {
        if(err.response.status === 401){
          message.error(
            `Usuario o Contraseña incorrecta, intentelo de nuevo.`,
            3
          );
          setLoading(false);
        }else{
          message.error(
            `Error Intentelo de nuevo o Contacte a Soporte. Código de Error: ${err.code}`,
            3
          );
          setLoading(false);
        }
      });
  };

  return (
    <div className="login-sections-container">
      <div className="login-section-left">
        <img
          src={`https://gepp.com.mx/wp-content/uploads/2017/12/GEPPBlanco-1024x571.png`}
          alt="logo"
          className="login-logo"
        />
      </div>
      <div className="login-section-right">
        <Card className="login-card">
          <Title level={4} className="login-text">
            Log In
          </Title>
          <Form {...layout} onFinish={onFinish} form={form}>
            <Form.Item
              label="Usuario"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Introduzca su Usuario",
                },
              ]}
            >
              <Input placeholder="Introduzca su Usuario..." />
            </Form.Item>
            <Form.Item
              label="Contraseña"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Introduzca su Contraseña",
                },
              ]}
            >
              <Input.Password placeholder="Introduzca su Contraseña..." />
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button htmlType="submit" type="primary" danger>
                Sign in
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
      {loading && <Loader />}
    </div>
  );
}